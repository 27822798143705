import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentFormatter from '../components/content-formatter'

class Page extends Component {
  render() {
    let post = this.props.data.wpPage
    let seo = post.seo
    let title = post.title

    return (
      <Layout className="contact-page" location={this.props.location}>
        <SEO title={'Contact'} />
        <div className="columns content">
          <ContentFormatter content={post?.content} />
        </div>
        <section className="section has-background-white contact-page content">
          <div className="container">
            <hr style={{
              marginTop: '0px'
            }}/>
            <form
            name="taktl-contact-1"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field" >
            <input type="hidden" name="form-name" value="taktl-contact-1" />
            <input type="hidden" name="bot-field" />
            <div className="columns is-multiline">
              <div className="column is-half">
                <div className="field">
                <label className="label" htmlFor="firstName">First Name *</label>
                <input type="text" id="firstName" name="firstName" className="input" />
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                <label className="label" htmlFor="lastName">Last Name *</label>
                <input type="text" id="lastName" name="lastName" className="input" />
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                <label className="label" htmlFor="company">Company / Firm</label>
                <input type="text" id="company" name="company" className="input" />
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                <label className="label" htmlFor="industry">Industry</label>
                <div className="select">
                <select name="industry" id="industry">
                  <option value="-none-">-none-</option>
                  <option value="Architect">Architect</option>
                  <option value="Interior Designer">Interior Designer</option>
                  <option value="Engineer - Struct/Mech/Civil">Engineer - Struct/Mech/Civil</option>
                  <option value="Graphic Designer">Graphic Designer</option>
                  <option value="Landscape Architect">Landscape Architect</option>
                  <option value="Lighting Designer">Lighting Designer</option>
                  <option value="Project Manager">Project Manager</option>
                  <option value="Resource Librarian">Resource Librarian</option>
                  <option value="Spec Writer - CSI">Spec Writer - CSI</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Construction Manager">Construction Manager</option>
                  <option value="Elevator Sales - Custom Cabs">Elevator Sales - Custom Cabs</option>
                  <option value="Estimator">Estimator</option>
                  <option value="Project Engineer">Project Engineer</option>
                  <option value="Buyer">Buyer</option>
                  <option value="Property Manager">Property Manager</option>
                  <option value="Developer">Developer</option>
                  <option value="General Manager">General Manager</option>
                  <option value="Owner">Owner</option>
                  <option value="Facilities Manager">Facilities Manager</option>
                  <option value="Operations Manager">Operations Manager</option>
                  <option value="Recycle Coord/Env Services">Recycle Coord/Env Services</option>
                  <option value="Consultant - Misc.">Consultant - Misc.</option>
                  <option value="Elevator Consultant">Elevator Consultant</option>
                  <option value="Sales Manager">Sales Manager</option>
                  <option value="Other">Other</option>
                </select>
                </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                <label className="label" htmlFor="streetAddress1">Street Address</label>
                <input type="text" id="streetAddress1" name="streetAddress1" className="input" />
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                <label className="label" htmlFor="streetAddress2">Street Address 2</label>
                <input type="text" id="streetAddress2" name="streetAddress2" className="input" />
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                <label className="label" htmlFor="city">City</label>
                <input type="text" id="city" name="city" className="input" />
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                <label className="label" htmlFor="state-province">State / Province</label>
                <div className="select">
                <select id="state-province" name="state-province">
                  <option value="-none-">-none-</option>
                  <option value="<United States>">&lt;United States&gt;</option>
                  <option value="Alabama (AL)">Alabama (AL)</option>
                  <option value="Alaska (AK)">Alaska (AK)</option>
                  <option value="Arizona (AZ)">Arizona (AZ)</option>
                  <option value="Arkansas (AR)">Arkansas (AR)</option>
                  <option value="California (CA)">California (CA)</option>
                  <option value="Colorado (CO)">Colorado (CO)</option>
                  <option value="Connecticut (CT)">Connecticut (CT)</option>
                  <option value="Delaware (DE)">Delaware (DE)</option>
                  <option value="District of Columbia (DC)">District of Columbia (DC)</option>
                  <option value="Florida (FL)">Florida (FL)</option>
                  <option value="Georgia (GA)">Georgia (GA)</option>
                  <option value="Guam (GU)">Guam (GU)</option>
                  <option value="Hawaii (HI)">Hawaii (HI)</option>
                  <option value="Idaho (ID)">Idaho (ID)</option>
                  <option value="Illinois (IL)">Illinois (IL)</option>
                  <option value="Indiana (IN)">Indiana (IN)</option>
                  <option value="Iowa (IA)">Iowa (IA)</option>
                  <option value="Kansas (KS)">Kansas (KS)</option>
                  <option value="Kentucky (KY)">Kentucky (KY)</option>
                  <option value="Louisiana (LA)">Louisiana (LA)</option>
                  <option value="Maine (ME)">Maine (ME)</option>
                  <option value="Maryland (MD)">Maryland (MD)</option>
                  <option value="Massachusetts (MA)">Massachusetts (MA)</option>
                  <option value="Michigan (MI)">Michigan (MI)</option>
                  <option value="Minnesota (MN)">Minnesota (MN)</option>
                  <option value="Mississippi (MS)">Mississippi (MS)</option>
                  <option value="Missouri (MO)">Missouri (MO)</option>
                  <option value="Montana (MT)">Montana (MT)</option>
                  <option value="Nebraska (NE)">Nebraska (NE)</option>
                  <option value="Nevada (NV)">Nevada (NV)</option>
                  <option value="New Hampshire (NH)">New Hampshire (NH)</option>
                  <option value="New Jersey (NJ)">New Jersey (NJ)</option>
                  <option value="New Mexico (NM)">New Mexico (NM)</option>
                  <option value="New York (NY)">New York (NY)</option>
                  <option value="North Carolina (NC)">North Carolina (NC)</option>
                  <option value="North Dakota (ND)">North Dakota (ND)</option>
                  <option value="Ohio (OH)">Ohio (OH)</option>
                  <option value="Oklahoma (OK)">Oklahoma (OK)</option>
                  <option value="Oregon (OR)">Oregon (OR)</option>
                  <option value="Pennyslvania (PA)">Pennyslvania (PA)</option>
                  <option value="Puerto Rico (PR)">Puerto Rico (PR)</option>
                  <option value="Rhode Island (RI)">Rhode Island (RI)</option>
                  <option value="South Carolina (SC)">South Carolina (SC)</option>
                  <option value="South Dakota (SD)">South Dakota (SD)</option>
                  <option value="Tennessee (TN)">Tennessee (TN)</option>
                  <option value="Texas (TX)">Texas (TX)</option>
                  <option value="Utah (UT)">Utah (UT)</option>
                  <option value="Vermont (VT)">Vermont (VT)</option>
                  <option value="Virginia (VA)">Virginia (VA)</option>
                  <option value="Virgin Islands (VI)">Virgin Islands (VI)</option>
                  <option value="Washington (WA)">Washington (WA)</option>
                  <option value="West Virginia (WV)">West Virginia (WV)</option>
                  <option value="Wisconsin (WI)">Wisconsin (WI)</option>
                  <option value="Wyoming (WY)">Wyoming (WY)</option>
                  <option value="<Canada>">&lt;Canada&gt;</option>
                  <option value="Alberta (AB)">Alberta (AB)</option>
                  <option value="British Columbia (BC)">British Columbia (BC)</option>
                  <option value="Manitoba (MB)">Manitoba (MB)</option>
                  <option value="New Brunswick (NB)">New Brunswick (NB)</option>
                  <option value="Newfoundland and Labrador (NL)">Newfoundland and Labrador (NL)</option>
                  <option value="Northwest Territories (NT)">Northwest Territories (NT)</option>
                  <option value="Nova Scotia (NS)">Nova Scotia (NS)</option>
                  <option value="Nunavut (NU)">Nunavut (NU)</option>
                  <option value="Prince Edward Island (PE)">Prince Edward Island (PE)</option>
                  <option value="Saskatchewan (SK)">Saskatchewan (SK)</option>
                  <option value="Ontario (ON)">Ontario (ON)</option>
                  <option value="Quebec (QC)">Quebec (QC)</option>
                  <option value="Yukon (YT)">Yukon (YT)</option>
                </select>
                </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="field">
                <label className="label" htmlFor="zipCode">Zip Code</label>
                <input type="text" id="zipCode" name="zipCode" className="input" />
                </div>
              </div>
              <div className="column is-one-half">
                <div className="field">
                <label className="label" htmlFor="email">Email *</label>
                <input type="email" id="email" name="email" className="input" />
                </div>
              </div>
              <div className="column is-one-half">
                <div className="field">
                <label className="label" htmlFor="phone">Telephone Number <small>Format: 123-456-7890</small></label>
                <input type="tel" id="phone" name="phone" className="input" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890"/>
                </div>
              </div>

              <div className="column is-full">
                <div className="field">
                <label className="label" htmlFor="subject">Subject *</label>
                <input className="input" type="text" id="subject" name="subject" />
                </div>
                <div className="field">
                <label className="label" htmlFor="message">Message *</label>
                <textarea className="textarea" id="message" name="message" className="textarea" />
                </div>
              </div>

              <div className="column is-full">
                <div style={{
                  display: 'flex'
                }}>
                <input type="checkbox" id="receiveUpdates" name="receiveUpdates" value="yes" defaultChecked={true} style={{
                  marginRight: '8px'
                }}/>
                <label className="label" htmlFor="receiveUpdates">
I would like to receive updates and announcements from TAKTL*</label>
                </div>
              </div>

              <div className="column is-full">
                <input type="submit" value="Submit" />
              </div>
            </div>

            </form>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(id: { eq: $id }) {
      title
      content
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
    allWpMenu(filter: {locations: {eq: HEADER_MENU}}) {
      nodes {
        menuItems {
          nodes {
            databaseId
            label
            title
            url
            parentDatabaseId
            cssClasses
          }
        }
      }
    }
  }
`
